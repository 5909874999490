.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.6;
  /*background-color: #61dafb;*/
}

.add-todo-input,
.edit-todo-input {
  outline: none;
}

.add-todo-input:focus,
.edit-todo-input:focus {
  border: none !important;
  box-shadow: none !important;
}

.view-opt-label,
.date-label {
  font-size: 0.8rem;
}

.edit-todo-input {
  font-size: 1.7rem !important;
}

.todo-actions {
  visibility: hidden !important;
}

.todo-item:hover .todo-actions {
  visibility: visible !important;
}

.todo-item.editing .todo-actions .edit-icon {
  display: none !important;
}
.completed {
  text-decoration: line-through;
}
.benn {
  padding: 15px!important;
  border-radius: 100px!important;
  width: 13%;
}

.bennD {
  margin-top: -35px!important;
}
.bg-primaryBenn {
  --bs-bg-opacity: 1;
  background-color: rgb(41 46 97) !important;
}
.pending-counter {
  background-color: #d6be5d;
  color: white;
  border-radius: 50px;
  display: inline-block;
  padding: 2px 12px;
  font-size: 0.9rem;
  font-weight: bold;
  border: 1px double rgba(159, 158, 158, 0.52);
}

.card-header {
  background-color: var(--bs-primary);
  color: white;
}


